import React, { Fragment } from "react";

const Step1 = ({
  register,
  handleSubmit,
  errors,
  onChange,
  nextStep,
  values,
}) => {
  const continueStep = (e) => {
    //e.preventDefault();
    //console.log(values);
    nextStep();
  };

  return (
    <Fragment>
      <div className="card has-background-success is-shadowless">
        <div className="card-body">
          <div className="field">
            <div className="control">
              <div className="columns">
                <div className="column is-one-half">
                  <label className="radio">
                    <input
                      type="radio"
                      name="nouvelEnfant"
                      value="Non"
                      checked={values.nouvelEnfant === "Non"}
                      onChange={onChange}
                    />
                    <span className="is-size-5">
                      Eleve ayant participé à la dernière session
                    </span>
                  </label>
                  {/* {values.nouvelEnfant === "Non" && (
                    <div className="field">
                      <div className="control">
                        <div className="columns">
                          <div className="column is-one-half">
                            <label className="radio">
                              <input
                                type="radio"
                                name="choixDeSessions"
                                value={"85 euros/15 cours"}
                                onChange={onChange}
                                checked={
                                  values.choixDeSessions === "85 euros/15 cours"
                                }
                              />
                              <span className="is-size-5">
                                85 euros/15 cours
                              </span>
                            </label>
                          </div>
                          <div className="column is-one-half">
                            <label className="radio">
                              <input
                                type="radio"
                                name="choixDeSessions"
                                value={"120 euros/20 cours"}
                                onChange={onChange}
                                checked={
                                  values.choixDeSessions ===
                                  "120 euros/20 cours"
                                }
                              />
                              <span className="is-size-5">
                                120 euros/20 cours
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="column is-one-half">
                  <label className="radio">
                    <input
                      type="radio"
                      name="nouvelEnfant"
                      value={"Oui"}
                      onChange={onChange}
                      checked={values.nouvelEnfant === "Oui"}
                    />
                    <span className="is-size-5">Nouvel élève</span>
                  </label>
                  {/* {values.nouvelEnfant === "Oui" && (
                    <div className="field">
                      <div className="control">
                        <div className="columns">
                          <div className="column is-one-half">
                            <label className="radio">
                              <input
                                type="radio"
                                name="choixDeSessions"
                                value={"125 euros/15 cours"}
                                onChange={onChange}
                                checked={
                                  values.choixDeSessions ===
                                  "125 euros/15 cours"
                                }
                              />
                              <span className="is-size-5">
                                125 euros/15 cours
                              </span>
                            </label>
                          </div>
                          <div className="column is-one-half">
                            <label className="radio">
                              <input
                                type="radio"
                                name="choixDeSessions"
                                value={"155 euros/20 cours"}
                                onChange={onChange}
                                checked={
                                  values.choixDeSessions ===
                                  "155 euros/20 cours"
                                }
                              />
                              <span className="is-size-5">
                                155 euros/20 cours
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div>
            {values.nouvelEnfant !== "" && (
              <div className="control has-text-right">
                <button
                  className="button is-info "
                  onClick={handleSubmit(continueStep)}
                >
                  Suivant
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Step1;
