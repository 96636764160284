import React, { useState } from 'react';

import ShoppingForm from '../shopping/ShoppingForm';
import ImageCarousel from '../ImageCarousel';

import bicBleu from '../../assets/shop/bicBleu.jpeg';
import bicBleuFonce from '../../assets/shop/bicBleuFonce.jpeg';
import bicOrange from '../../assets/shop/bicOrange.jpeg';
import bicRose from '../../assets/shop/bicRose.jpeg';
import bicRouge from '../../assets/shop/bicRouge.jpeg';
import bicVert from '../../assets/shop/bicVert.jpeg';

import portecles from '../../assets/shop/portecles.jpg';

import blue01 from '../../assets/shop/blue01.jpg';
import blue02 from '../../assets/shop/blue02.jpg';
import blue03 from '../../assets/shop/blue03.jpg';
import blue04 from '../../assets/shop/blue04.jpg';

import red01 from '../../assets/shop/red01.jpg';
import red02 from '../../assets/shop/red02.jpg';
import red03 from '../../assets/shop/red03.jpg';
import red04 from '../../assets/shop/red04.jpg';

import black01 from '../../assets/shop/black01.jpg';
import black02 from '../../assets/shop/black02.jpg';
import black03 from '../../assets/shop/black03.jpg';
import black04 from '../../assets/shop/black04.jpg';

const Shopping = () => {
  const [showMessage, setShowMessage] = useState(false);

  // const openQuote = () => {
  //   if (showMessage) {
  //     setTimeout(() => setShowMessage(false), 5000);
  //   }
  // };
  const toggleModal = () => {
    const modal = document.getElementById('modalOrder');
    modal.classList.toggle('is-active');
  };

  return (
    <section>
      <div className="box has-borderless" style={{ margin: 1 + 'em', boxShadow: 'none' }}>
        <div className="columns is-multiline">
          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Porte-clés Soutien Bleu</p>
                </div>
              </div>
              <div className="card-image">
                <ImageCarousel image1={blue01} image2={blue02} image3={blue03} image4={blue04} image5={portecles} />
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le porte-clés Soutien des Petits Abous dispose d'un ouvre bouteille en métal, d'une LED blanche,
                    d'un mètre et d'un jeton de caddie intégré <br />
                    Largeur 4,90 cm X Hauteur 8,10 cm
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 6,99/pièce</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Porte-clés Soutien Rouge</p>
                </div>
              </div>
              <div className="card-image">
                <ImageCarousel image1={red01} image2={red02} image3={red03} image4={red04} image5={portecles} />
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le porte-clés Soutien des Petits Abous dispose d'un ouvre bouteille en métal, d'une LED blanche,
                    d'un mètre et d'un jeton de caddie intégré <br />
                    Largeur 4,90 cm X Hauteur 8,10 cm
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 6,99/pièce</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Porte-clés Soutien Noir</p>
                </div>
              </div>
              <div className="card-image">
                <ImageCarousel image1={black01} image2={black02} image3={black03} image4={black04} image5={portecles} />
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le porte-clés Soutien des Petits Abous dispose d'un ouvre bouteille en métal, d'une LED blanche,
                    d'un mètre et d'un jeton de caddie intégré <br />
                    Largeur 4,90 cm X Hauteur 8,10 cm
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 6,99/pièce</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-multiline">
          {/* <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur turquoise</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicBleu} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur blue</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicBleuFonce} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur orange</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicOrange} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="columns is-multiline">
          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur rose</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicRose} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur rouge</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicRouge} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-header">
                <div className="card-header-title">
                  <p>Bic de couleur vert</p>
                </div>
              </div>
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={bicVert} alt="" />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>
                    Le Bic Abous avec Stylet offre qualité et confort d'écriture à un prix abordable. Avec son corps
                    métallisé et son encre bleue, ce stylo est le cadeau idéal pour vous.
                  </p>
                </div>
              </div>
              <div className="card-footer">
                <div className="card-content">
                  <p>€ 2,00/pièce</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showMessage && (
          <div class="notification is-info">
            <p>Merci pour votre commande. L'équipe vous recontactera au plus vite.</p>
          </div>
        )}
        <div className="columns is-mobile is-centered">
          <div className="column is-half">
            <button className="button is-info is-fullwidth" type="button" onClick={toggleModal}>
              Commandes
            </button>
          </div>
        </div>
      </div>
      <ShoppingForm showMessage={showMessage} setShowMessage={setShowMessage} />
    </section>
  );
};

export default Shopping;
