import React from 'react';
import newSession from '../../assets/newAffiche.png';

const NewSession = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={newSession} alt="affice nouvelle sessions" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">NEWS</h1>
        <div className="content is-size-5">
          <p>Bonjour à tous,</p>
          <p>
            Olàlàlà, beaucoup de choses ont changés depuis notre dernière actualité.
            <br /> Pour finir, notre réouverture a pu se faire plus rapidement que prévu car nous avons reçu
            l’autorisation communale de reprendre nos activités.
          </p>
          <p>
            Ensuite, nous avons fait face à de nouvelles règles gouvernementales : bulles de 10 enfants (toujours les
            mêmes). Comme vous le saviez, nous avions organisé nos cours Zumba Kids qui nous ont donc bloqué dans notre
            reprise. Mais, les Petits Abous s’adapte… Nous avons tout réorganisé afin de pouvoir répondre à toutes les
            règles imposées et pouvoir continuer de donner nos activités. Ainsi, cette année 2021 ne sera pas divisée en
            demi-session comme à notre habitude, mais nos cours seront étalés sur toute l’année une semaine sur 2 afin
            de garantir nos bulles de 10 enfants et garder notre formule 20 cours (affiche à l’appui).
          </p>
          <p>Il y aura donc 4 groupes de 10 enfants :</p>
          <ul>
            <li>1 groupe de 2 à 4 ans participant à la formule 15 cours</li>
            <li>1 groupe de 2 à 4 ans participant à la formule 20 cours</li>
            <li>1 groupe de 4 à 6 ans participant à la formule 15 cours</li>
            <li>1 groupe de 4 à 6 ans participant à la formule 20 cours</li>
          </ul>
          <p>Ces 4 groupes auront cours en tournante une semaine sur 2 afin de garantir aucun mélange de bulles.</p>
          <p>
            Nous espérons que d’ici janvier 2022, nous pourrons reprendre nos cours normalement sans restriction aussi
            compliquée.{' '}
          </p>
          <p>Prenez soin de vous et à très bientôt</p>
          <p>Talisa</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>07 février 2021</p>
      </div>
    </div>
  );
};

export default NewSession;
