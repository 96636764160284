import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import BtnLikeUs from '../BtnLikeUs';
import Instagram from '../Instagram';
import Logo from '../../assets/contactLogo3.png';

const Contact = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const onChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.destination = process.env.REACT_APP_FORM_SENTTO_EMAIL;
    data.from = process.env.REACT_APP_FORM_NOREPLY;
    try {
      await axios.post('https://api.denegee.be/api/email', data);
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
    if (!showMessage) {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }
    onReset();
  };

  const onReset = () => {
    reset();
  };
  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        {loading && (
          <progress className="progress is-large is-warning" max="100">
            15%
          </progress>
        )}
        <div className="content">
          <h1 className="title has-text-info">Contactez-nous</h1>
          {showMessage && (
            <div class="notification is-info">
              <p>Merci pour votre message. L'équipe vous recontactera au plus vite.</p>
            </div>
          )}
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="content">
              <figure className="image">
                <img src={Logo} alt="" />
              </figure>
            </div>
          </div>
          <div className="column is-half">
            <div className="content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="label">Votre adresse mail</div>
                  <div className="control has-icons-left has-icons-right">
                    <input
                      id="inputEmail"
                      className="input"
                      type="email"
                      onChange={onChange}
                      placeholder="Votre adresse mail"
                      {...register("email",{
                        required: true,
                        // eslint-disable-next-line
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-envelope"></i>
                    </span>

                  </div>
                </div>

                <div className="field">
                  <label className="label">Votre nom</label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="Votre nom"
                      onChange={onChange}
                      {...register("name", { required: true })}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>

                  </div>
                </div>

                <div className="field">
                  <label className="label">Sujet</label>
                  <div className="control has-icons-left">
                    <input
                      name="subject"
                      className="input"
                      type="text"
                      onChange={onChange}
                      placeholder="C'est quel sujet"
                      {...register("subject", { required: true })}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-inbox"></i>
                    </span>

                  </div>
                </div>

                <div className="field">
                  <label className="label">Votre message</label>
                  <div className="control has-icons-left">
                    <textarea
                      className="textarea"
                      name="message"
                      onChange={onChange}
                      {...register("message", { required: true })}
                    ></textarea>

                  </div>
                </div>
                <div className="buttons">
                  <button className="button is-info" type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>
                    Envoyer
                  </button>
                  <button className="button" onClick={onReset}>
                    Annuler
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="content has-text-centered">
          <p className="title">Directrice: </p>
          <p>Talisa Ceuppens</p>
          <p>Les Petits Abous ASBL</p>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="content has-text-left">
              <span className="tag is-info is-large">
                <i className="fas fa-envelope"></i>
              </span>
              <p>PAR E-MAIL</p>
              <p>
                <a href="mailto:lespetitsabous@hotmail.com">LesPetitsAbous@hotmail.com</a>
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="content has-text-left">
              <span className="tag is-info is-large">
                <i className="fas fa-phone"></i>
              </span>
              <p>PAR TELEPHONE</p>
              <ul>
                <li>0495/78 96 51</li>
                <li>0496/68 43 64</li>
              </ul>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="content">
              <span className="tag is-info is-large">
                <i className="fas fa-users"></i>
              </span>
              <p>VIA LES RÉSEAUX SOCIAUX</p>
              <div>
                <BtnLikeUs />
                <Instagram />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
