import React from 'react';
import profile from '../../assets/profile.jpeg';
// import calendar15 from '../../assets/calendar15.png';
// import calendar20 from '../../assets/calendar20.png';
//import calendar1520 from '../../assets/calendar1520.png';
//import generalcalendar from '../../assets/generalCalendar.jpg';
// import newOrganisation from '../../assets/newAffiche.png';
import newOrganisation from '../../assets/newOrganisation.jpg';
import calendar23 from '../../assets/calendar24.jpg';

const Cours = () => {
  const showQuote = (e) => {
    const el = document.getElementById('quoteMessage');
    if (el.style.display === 'none') {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  };
  const showQuoteGenral = (e) => {
    const el = document.getElementById('quoteMessageGeneral');
    if (el.style.display === 'none') {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  };
  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">J</span>e me présente...
          </h1>
          <article className="media is-block-mobile">
            <figure className="media-left">
              <p className="image is-128x128">
                <img src={profile} alt="" />
              </p>
            </figure>
            <div className="media-content">
              <p>
                Je m’appelle Talisa et j’ai 27 ans. Je suis institutrice maternelle de profession. La danse fait partie
                de ma vie depuis que j’ai 5 ans.
              </p>
              <p>
                Depuis presque 8 ans maintenant, j’ai lié mon hobby à mon métier pour devenir professeur de danse. J’ai
                commencé à travailler en tant que bénévole pour donner mes cours pour ensuite décider d’ouvrir ma propre
                école de danse. Je me suis donc lancée dans toutes les démarches pour enfin donner naissance à mon école
                « Les Petits Abous » en août 2018.
              </p>
            </div>
          </article>
        </div>
      </div>

      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">N</span>os cours
          </h1>
          <article className="media">
            <div className="media-content">
              <p>Nos cours sont consacrés aux enfants de 2 à 6 ans.</p>
              <p>Notre association a pour objectifs :</p>
              <ul>
                <li>
                  Le développement personnel et l’équilibre physique et mental des individus en général et des enfants
                  de 2 à 6 ans en particulier par la pratique du sport.
                </li>
                <li>La créativité de l’enfant par des activités variées d’expression et de création.</li>
                <li>
                  L’intégration sociale de l’enfant, dans le respect de ses différences, dans un esprit de coopération
                  et dans une approche multiculturelle.
                </li>
              </ul>
              <p>
                Pour une question d’organisation et de vie en groupe, les enfants sont inscrits à la session complète de
                danse de 15 cours couvrant une demi-année scolaire. Durant une année académique, il y a donc 2 sessions.
                La première couvre la période de septembre à janvier et la seconde couvre la période de février à juin.
                Les cours ne sont pas donnés pendant les congés scolaires et les jours fériés officiels (sauf pour des
                raisons exceptionnelles).
              </p>
            </div>
          </article>
        </div>
      </div>

      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">N</span>os horaires
          </h1>
          <article className="media">
            <div className="media-content">
              <p>Les activités se déroulent le samedi matin</p>
              <ul>
                <li>De 10h à 11h pour les enfants de 2 à 4 ans</li>
                <li>De 11h à 12h pour les enfants de 4 à 6 ans</li>
              </ul>
              <p>Durant cette heure de cours, votre enfant vivra 3 séquences bien précises:</p>
              <ul>
                <li>un échauffement</li>
                <li>
                  le corps de leçon où l’enfant apprendra à réaliser des mouvements plus précis (en vue d’un petit
                  spectacle en fin de session)
                </li>
                <li>une relaxation</li>
              </ul>
            </div>
          </article>
          <article className="media">
            <div className="media-content">
              <p>
              Cette année, la session s’étale à nouveau sur une demi-année avec 1 groupe de petits et 1 groupe de grands pour 15 cours chaque semaine (hors vacances scolaires)
              </p>
              <figure className="image is-square">
                <img src={newOrganisation} alt="affiche de la nouvelle organisation" />
              </figure>
            </div>
          </article>
        </div>
      </div>

      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title has-text-info">Calendrier</h1>
          <p>
            Ce calendrier peut toujours être modifié selon certains évènements imprévus. Merci de s’informer via la page
            et le profil Facebook ainsi que sur le site internet.
          </p>
          <figure className="image is-16by9">
            <img src={calendar23} alt="Calendrier" />
          </figure>
        </div>
      </div>
      {/* <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">C</span>alendrier
          </h1>
          <article className="media">
            <div className="media-content">
              <p>
                Ce calendrier peut toujours être modifié selon certains évènements imprévus. Merci de s’informer via la
                page et le profil Facebook ainsi que sur le site internet.
              </p>
              <p>
          Calendrier Session Février – Décembre 2021
            <span>
              <a href="#!" onClick={showQuote}>
                *
              </a>
            </span>
          </p>
          <blockquote className="content" id="quoteMessage" style={{ display: 'none' }}>
            * Dû aux nouvelles restrictions gouvernementales, 2 calendriers sont disponibles en fonction de la formule
            prise (15 ou 20 cours).
          </blockquote>
          <figure className="image is-16by9">
            <img src={calendar1520} alt="Calendrier 15 cours" />
          </figure>

          <p>
          Calendrier Session Janvier – Juin 2022 
            <span>
              <a href="#!" onClick={showQuoteGenral}>
                *
              </a>
            </span>
          </p>
          <blockquote className="content" id="quoteMessageGeneral" style={{ display: 'none' }}>
            * Ce calendrier est toujours susceptible de changer pendant l'année.
          </blockquote>
          <figure className="image is-16by9">
            <img src={generalcalendar} alt="Calendrier General" />
          </figure>

          
            </div>
          </article>
          <p className="has-text-right">Modifié le 21/11/2021</p>
        </div>
      </div> */}

      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">N</span>os spectacles
          </h1>
          <article className="media">
            <div className="media-content">
              <p>
                Dans la mesure du possible, un petit spectacle sera organisé lors du 15e cours de danse. Votre enfant vous montrera une petite chorégraphie apprise pendant la session.
              </p>
              {/* <p>
                Une session de danse comprend 15 séances comptant 14 cours de danse où votre enfant apprendra une
                chorégraphie pour le spectacle qui se déroulera à la 15e séance. A cette date-là, deux personnes par
                enfant maximums auront l’opportunité de rentrer dans la grande salle (qui se sera transformée en salle
                de présentation) et vous aurez l’occasion d’assister au petit show des Petits Abous. Le groupe des
                petits ainsi que le groupe des grands auront l’occasion de vous présenter leur travail.
              </p>
              <p>
                <u>Affice et information du prochain spectacle:</u>
              </p>
              <p>Indisponible pour le moment</p> */}
            </div>
          </article>
        </div>
      </div>

      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title">
            <span className="has-text-info title">N</span>os tarifs
          </h1>
          <article className="media">
            <div className="media-content">
              {/* <p style={{ textDecoration: 'underline' }}>
                TARIFS EXCEPTIONNELS DUS AUX CONSEQUENCES DU CORONA VIRUS*: 2021
              </p>
              <div className=""></div>
              <blockquote className="content has-text-centered">
                <p className="has-text-grey">
                  * Dû aux cours perdus lors du confinement, l’équipe des Petits Abous offre la possibilité
                  exceptionnelle de suivre 20 cours pour la session de septembre à janvier 2021 au lieu de 15. Les 5
                  cours supplémentaires seront des cours de « Kids Zumba » et se donneront principalement pendant les
                  vacances légales de Toussaint et de Noël. Les groupes seront organisés en fonction de ces formules.
                </p>
              </blockquote> */}
              {/* <div className="table-container"> */}
                {/* <table className="table is-borderless has-background-success is-fullwidth">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className="">
                        <p className="has-text-centered">15 cours</p>
                      </th>
                      <th className="">
                        <p className=" has-text-centered">
                          <span className="has-text-danger">NEW </span>20 cours
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="">
                        <p className="has-text-centered">Pour l’élève ayant participé à la session février-juin 2020</p>
                      </td>
                      <td className=" ">
                        <p className="has-text-centered">€ 85</p>
                      </td>
                      <td className=" ">
                        <p className="has-text-centered">€ 120</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="">
                        <p className="has-text-centered">Nouvel élève</p>
                      </td>
                      <td className=" is-one-third">
                        <p className="has-text-centered">€ 125</p>
                      </td>
                      <td className=" is-one-third">
                        <p className="has-text-centered">€ 155</p>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              {/* </div> */}
              {/* <p className="has-text-centered">
                120 € / 15 cours
              </p>
              <p className="has-text-centered">
              Le prix comprend les cours ainsi que les frais d’inscription et l’assurance.
              </p>
              <p className="has-text-centered has-text-info">
              Si un frère ou une sœur de la même famille s’inscrit également, une réduction de 5 euros sera déduite du montant total.
              </p>
              <p className="has-text-centered">INTERVENTION MUTUELLE</p>
              <p className="has-text-centered">
                Récupérez entre 15 et 55 € par an en nous fournissant le document mutuel pour les clubs sportifs Les
                Petits Abous sont affiliés auprès de toutes les mutuelles belges intervenant dans les abonnements
                sportifs, plus d’infos à l’accueil…
              </p> */}
              <div>
                {/* <p style={{ textDecoration: 'underline' }}>TARIFS 2021-2022</p> */}
                <p className="is-size-3 has-text-centered">120 € / 15 cours</p>
                <p className="has-text-centered">
                  Le prix comprend les cours ainsi que les frais d’inscription et l’assurance
                </p>
                <p className="has-text-centered has-text-info">
                  Si un frère ou une sœur de la même famille s’inscrit également, une réduction de 5 euros sera déduite
                  du montant total.
                </p>
                <p className="has-text-centered">INTERVENTION MUTUELLE</p>
                <p className="has-text-centered">
                  Récupérez entre 15 et 55 € par an en nous fournissant le document mutuel pour les clubs sportifs Les
                  Petits Abous sont affiliés auprès de toutes les mutuelles belges intervenant dans les abonnements
                  sportifs, plus d’infos à l’accueil…
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Cours;
