import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Confirm from "./Confirm";
import Success from "./Success";

const RegisterForm = () => {
  const { register, errors, handleSubmit } = useForm();
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    nouvelEnfant: "",
    choixDeSessions: "",
    nomEnfant: "",
    prenomEnfant: "",
    sexeEnfant: "",
    ageEnfant: "",
    naissanceEnfant: "",
    ouVitEnfant: "",
    adresseEnfant: "",
    nomMaman: "",
    prenomMaman: "",
    telephoneMaman: "",
    emailMaman: "",
    nomPapa: "",
    prenomPapa: "",
    telephonePapa: "",
    emailPapa: "",
    santeEnfant: "",
    iceTelephone: "",
    authorisationImage: "",
    profileFacebook: "",
    luEtApprove: "",
    payerTotalite: "",
    soeurFrere: "",
  });

  // Proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go Back to the previous step
  const previousStep = () => {
    setStep(step - 1);
  };

  const onChange = (e) => {
    //e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  console.log({data});

  switch (step) {
    case 1:
      return (
        <Step1
          register={register}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          nextStep={nextStep}
          values={data}
        />
      );
    case 2:
      return (
        <Step2
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 3:
      return (
        <Step3
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 4:
      return (
        <Step4
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 5:
      return (
        <Step5
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 6:
      return (
        <Step6
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 7:
      return (
        <Step7
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 8:
      return (
        <Step8
          register={register}
          nextStep={nextStep}
          onChange={onChange}
          errors={errors}
          handleSubmit={handleSubmit}
          previousStep={previousStep}
          values={data}
        />
      );
    case 9:
      return (
        <Confirm
          nextStep={nextStep}
          previousStep={previousStep}
          values={data}
        />
      );
    case 10:
      return <Success />;
    default:
      return <h1 className="title has-text-danger">Y a rien a faire ici</h1>;
  }
};

export default RegisterForm;
