import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  useEffect(() => {
    const burger = document.querySelector(".navbar-burger");
    const menu = document.getElementById("myNavBar");

    burger.addEventListener("click", () => {
      burger.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });
  }, []);

  const toggle = () => {
    const burger = document.querySelector(".navbar-burger");
    const menu = document.getElementById("myNavBar");

    burger.classList.toggle("is-active");
    menu.classList.toggle("is-active");
  };

  return (
    <div className="container">
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
        style={{ borderRadius: "30px" }}
      >
        <div className="navbar-brand">
          <a
            href="#!"
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="myNavBar"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="myNavBar" className="navbar-menu">
          <div className="navbar-start">
            <Link
              to="/"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              <i className="fas fa-home fa-2x"></i>
            </Link>
            <Link
              to="/cours"
              className="navbar-item threed "
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Cours
            </Link>
            <Link
              to="/news"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Actualités
            </Link>
            {/* <Link
              to="/shop"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Boutique
            </Link> */}
            <Link
              to="/access"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Accès
            </Link>
            <Link
              to="/contact"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Contact
            </Link>
            <Link
              to="/register"
              className="navbar-item threed"
              style={{ marginRight: "5rem" }}
              onClick={toggle}
            >
              Inscription
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
