import React from "react";

const BtnLikeUs = () => {
  return (
    <div>
      <p>
        <a
          href="https://www.facebook.com/LesPetitsAbousASBL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-square text-primary fa-2x"></i> Les
          Petits Abous ASBL
        </a>
      </p>
    </div>
  );
};

export default BtnLikeUs;
