import React, { Fragment } from "react";

const Step4 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  //console.log(values);
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">
            3 - Problèmes de santé à me signaler (asthme, allergies, ...)
          </h1>
        </header>
        <div className="card-body">
          <textarea
            className="textarea"
            defaultValue={values.santeEnfant}
            onChange={onChange}
            name={"santeEnfant"}
          ></textarea>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step4;
