import React from 'react';

import indoorSport from '../../assets/indoorSport.jpg';

const IndoorSport = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={indoorSport} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">ATTENTION : CHANGEMENT D'INFORMATIONS</h1>
        <div className="content is-size-5">
          <p>Nous venons de recevoir le protocole du Sport du Cabinet de la Ministre Glatigny.</p>
          <p>A la surprise générale, ce dernier est plus strict que ce qui avait été annoncé.</p>
          <p>NOTEZ QUE LE SPORT INDOOR RECONFINE... même pour les - de 12 ans!</p>
          <p>
            <a
              href="https://aes-aisf.be/wp-content/uploads/2020/11/20201031_Protocole-activite-physique-et-sportive-VF.pdf?fbclid=IwAR08MsHiAOP6A5nhVXnlgWS1Kv495TTP2baVCLiRJhvU3wgAbiUOohdVDA0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voici le protocole
            </a>
          </p>
          <p>Nous sommes donc attristé de vous annoncer notre fermeture jusqu'à nouvel ordre...</p>
          <p>
            Ce que nous vous proposons, ce sera des cours en visioconférence. Je vais faire mes petites recherches et
            choisir un logiciel que nous utiliserons. Nous vous tenons au courant.
          </p>
          <p>
            Au niveau du remboursement, nous avons anticipé et pourrons le faire. Tout sera calculé en fin de session en
            tenant compte du nombre de cours perdus et en fonction de ce que nous paierons ou non lors de ce
            confinement. Et, ce, si le report de cours est impossible.{' '}
          </p>
          <p>
            Nous sommes dans l'incapacité de vous informer de la durée de ce confinement donc nous ne pouvons encore
            rien prévoir en ce moment pour les cours que vous perdrez.
          </p>
          <p>
            Prenez soin de vous surtout ! <br />
            Et a très vite, je l'espère <br />
            En tout cas, nous garderons contact avec vous un maximum et vous tiendrons au courant des suites des mesures{' '}
            <br />
            Talisa
          </p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>01 novembre 2020</p>
      </div>
    </div>
  );
};

export default IndoorSport;
