import React from "react";
import fausseAlert from "../../assets/deconfinement2.jpeg";

const FausseAlert = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={fausseAlert} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">
          Fausse alerte pour notre déconfinement
        </h1>
        <div className="content is-size-5">
          <p>
            Ce mercredi 13 mai 2020, la première ministre s’est exprimée face à
            la Belgique et nous a donné de faux espoir, à nous, aux parents et
            aux élèves. En effet, cette dernière a donné l’impression d’annoncer
            la réouverture des cours en clubs sportifs avec maximum 20 personnes
            et avec la présence d’un entraîneur.
          </p>
          <p>
            Malheureusement, ce n’était qu’une mauvaise interprétation de ces
            propos. En réalité, à partir de ce 18 mai, le conseil National de
            Sécurité a décidé d’autoriser la reprise des entraînements en
            extérieur, sans contact, pour des groupes de maximum 20 personnes et
            en respectant les règles d’hygiène.{" "}
          </p>
          <p>
            La ministre a récemment publié un message que je vous partage :{" "}
          </p>
          <p>Déconfinement progressif du secteur sportif</p>
          <p>
            L’arrêté ministériel du fédéral – traduisant légalement les mesures
            annoncées suite au Conseil national de sécurité de mercredi – a été
            publié ce vendredi 15 mai. Sur cette base, j’ai envoyé aujourd’hui
            une circulaire aux fédérations sportives. Celle-ci contient les
            modalités de reprise pour ce lundi 18 mai.{" "}
          </p>
          <p>
            Sont autorisés les entraînements et leçons sportifs réguliers à
            l'air libre n'impliquant pas de contacts physiques, dans un contexte
            organisé, c’est-à-dire par un club ou une association sportive, en
            groupe de maximum 20 personnes, toujours en présence d'un entraîneur
            ou d'un superviseur majeur, et moyennant le respect d'une distance
            d'1,5 mètre entre chaque personne. Il est recommandé de porter cette
            distance à 5 mètres en cas de pratique sportive intensive en raison
            de l’hyperventilation et la production de gouttelettes qui
            l’accompagnent.
          </p>
          <p>Nous sommes donc en attente de la prochaine phase.</p>
          <p>Prenez soin de vous</p>
          <p>L'équipe des Petits Abous</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>17 mai 2020</p>
      </div>
    </div>
  );
};

export default FausseAlert;
