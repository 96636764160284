import React, { Fragment } from "react";

const Step8 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  //console.log(values);
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">7 - Frére ou Soeur?</h1>
        </header>
        <div className="card-body">
          <p>
            Avez-vous un frère ou une sœur que vous voudriez inscrire aux cours
            de danse également ?
          </p>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="control">
                <label className="radio">
                  <input
                    type="radio"
                    value={"oui"}
                    onChange={onChange}

                   name={"soeurFrere"}
                  />
                  <span className="is-size-5">Oui</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    value={"non"}

                    onChange={onChange}
                    name={"soeurFrere"}
                  />
                  <span className="is-size-5">Non</span>
                </label>
              </div>
            </div>

          </div>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step8;
