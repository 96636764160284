import React from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './ImageCarousel.css';

const ImageCarousel = ({ image1, image2, image3, image4, image5 }) => {
  return (
    <Carousel showArrows={true}>
      <div>
        <img src={image1} alt="" />
      </div>
      <div>
        <img src={image2} alt="" />
      </div>
      <div>
        <img src={image3} alt="" />
      </div>
      <div>
        <img src={image4} alt="" />
      </div>
      <div>
        <img src={image5} alt="" />
      </div>
    </Carousel>
  );
};

export default ImageCarousel;
