import React from "react";
import soustien from "../../assets/soutiens.jpg";

const Gouvernement = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={soustien} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">
          Nouveautés à propos de l'enseignement culturel
        </h1>
        <div className="content is-size-5">
          <p>
            Le gouvernement a annoncé un prolongement du confinement jusqu'au 3
            mai inclus, ce qui conserne nos activités également...
          </p>
          <p>
            Néanmoins, cela ne signifie pas que tout reviendra à la normale le 3
            mai. En effet, un processus de déconfinement va être mis en place
            mais nous savons, écoles de danse, enseignement culturel, que nous
            serons un secteur oublié. Nous ne sommes pas « indispensables » et
            nous causons des rassemblements. En effet, nous sommes un secteur où
            nous regroupons des enfants qui ne sont pas ensemble depuis début
            mars et le risque de contagion est fort. Nous nous devons de penser
            à protéger nos élèves et nos parents.
          </p>
          <p>
            Cependant, cette crise cause de réels dégâts dans la gestion de
            comptabilité des asbl. En effet, l’Etat ne nous offre aucune aide
            pour que nos écoles de danse restent la tête en dehors de l’eau. La
            prime dont vous entendez parler, est plus dure à obtenir que ce
            qu’elle paraît. Personnellement, notre asbl ne correspond pas aux
            exigences demandées pour en bénéficier car celle-ci est trop petite
            et ne possède aucun employé à payer.
          </p>
          <p>
            Ceci n’est pas le plus dur pour moi mais le désintérêt de la part de
            la première ministre à notre égard. Lors de la séance plénière de la
            Chambre ce 16 avril 2020, M. De Smet (NdlR : député Défi) a
            interpellé la première ministre sur notre situation. Une des
            réponses nous a fortement troublé en tant qu’artistes et directeurs
            d’école de danse. Je la cite : "Je sais que [ces mesures concernant
            les allocations de chômage des artistes] sont une maigre consolation
            quand on est un artiste et qu’on a envie, et surtout qu’on a besoin,
            de s’exprimer publiquement. J’espère qu’ils pourront trouver
            d’autres moyens de le faire, pour passer comme les autres, à leur
            manière, cette crise sans trop d’encombres".
          </p>
          <p>
            Outre le fait que nous ne pouvons pas nous épanouir dans nos
            activités, nous sommes face à des problèmes financiers et l’état
            doit entendre ce problème. Certaines asbl comptaient sur leur
            rentrée des stages et des spectacles de fin d’année. Pour l’instant,
            ce n’est pas mon cas n’ayant pas de rentrée d’argent prévu avec la
            nouvelle session en septembre. Cependant, nous n’en dirons pas
            autant lors de la reprise quand nous allons devoir faire des gestes
            commerciaux à la hauteur des trois-quarts des cours perdus pendant
            ce confinement.
          </p>
          <p>
            Ainsi, plusieurs choses ont été mises en place avec l’aide de mes
            confrères de la danse.
          </p>
          <ul>
            <li>
              Dance Corner a rédigé une pétition que les écoles de danse et
              moi-même avons signé. La ministre de la culture en a entendu
              parler sans réellement réagir face à cela. Elle n’a donc pas eu
              l’effet escompté mais nous espérons toujours qu’elle fera réagir à
              un moment donné.
            </li>
            <li>
              Le directeur de l’école de danse « Studio Vibes » a eu l’idée de
              créer avec son graphiste, un logo à partager. (cfr. Au logo de cet
              article). C’est un appel à l’aide qui concerne tout le monde.
              L’idée est de partager ce logo partout sur les réseaux sociaux
              pour inonder les fils d’actualité de tout le monde. L’union fait
              la force et cette aide est gratuite
            </li>
            <li>
              Dernièrement, nous avons également évoqué l’idée de rédiger une
              autre pétition que tout le monde pourra signer cette fois-ci. Une
              fois celle-ci rédigée, je vous informerai pour pouvoir signer et
              nous soutenir dans nos démarches.
            </li>
          </ul>
          <p>
            Nous espérons que nos démarches porteront nos fruits et feront
            réagir le gouvernement.
          </p>
          <p>Prenez-soin de vous et protégez-vous un maximum</p>
          <p>Merci à tous !</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>20 avril 2020</p>
      </div>
    </div>
  );
};

export default Gouvernement;
/**
 * <article className="media">
      <figure className="media-left">
        <p className="image is-128x128">
          <img src={soustien} alt="" />
        </p>
      </figure>
      <div className="media-content">
        <span style={{ float: "right" }}>20 avril 2020</span>
        <h1 className="title has-text-info ">
          Nouveautés à propos de l'enseignement culturel
        </h1>
        <p>
          Le gouvernement a annoncé un prolongement du confinement jusqu'au 3
          mai inclus, ce qui conserne nos activités également...
        </p>
        <p>
          Néanmoins, cela ne signifie pas que tout reviendra à la normale le 3
          mai. En effet, un processus de déconfinement va être mis en place mais
          nous savons, écoles de danse, enseignement culturel, que nous serons
          un secteur oublié. Nous ne sommes pas « indispensables » et nous
          causons des rassemblements. En effet, nous sommes un secteur où nous
          regroupons des enfants qui ne sont pas ensemble depuis début mars et
          le risque de contagion est fort. Nous nous devons de penser à protéger
          nos élèves et nos parents.
        </p>
        <p>
          Cependant, cette crise cause de réels dégâts dans la gestion de
          comptabilité des asbl. En effet, l’Etat ne nous offre aucune aide pour
          que nos écoles de danse restent la tête en dehors de l’eau. La prime
          dont vous entendez parler, est plus dure à obtenir que ce qu’elle
          paraît. Personnellement, notre asbl ne correspond pas aux exigences
          demandées pour en bénéficier car celle-ci est trop petite et ne
          possède aucun employé à payer.
        </p>
        <p>
          Ceci n’est pas le plus dur pour moi mais le désintérêt de la part de
          la première ministre à notre égard. Lors de la séance plénière de la
          Chambre ce 16 avril 2020, M. De Smet (NdlR : député Défi) a interpellé
          la première ministre sur notre situation. Une des réponses nous a
          fortement troublé en tant qu’artistes et directeurs d’école de danse.
          Je la cite : "Je sais que [ces mesures concernant les allocations de
          chômage des artistes] sont une maigre consolation quand on est un
          artiste et qu’on a envie, et surtout qu’on a besoin, de s’exprimer
          publiquement. J’espère qu’ils pourront trouver d’autres moyens de le
          faire, pour passer comme les autres, à leur manière, cette crise sans
          trop d’encombres".
        </p>
        <p>
          Outre le fait que nous ne pouvons pas nous épanouir dans nos
          activités, nous sommes face à des problèmes financiers et l’état doit
          entendre ce problème. Certaines asbl comptaient sur leur rentrée des
          stages et des spectacles de fin d’année. Pour l’instant, ce n’est pas
          mon cas n’ayant pas de rentrée d’argent prévu avec la nouvelle session
          en septembre. Cependant, nous n’en dirons pas autant lors de la
          reprise quand nous allons devoir faire des gestes commerciaux à la
          hauteur des trois-quarts des cours perdus pendant ce confinement.
        </p>
        <p>
          Ainsi, plusieurs choses ont été mises en place avec l’aide de mes
          confrères de la danse.
        </p>
        <ul>
          <li>
            Dance Corner a rédigé une pétition que les écoles de danse et
            moi-même avons signé. La ministre de la culture en a entendu parler
            sans réellement réagir face à cela. Elle n’a donc pas eu l’effet
            escompté mais nous espérons toujours qu’elle fera réagir à un moment
            donné.
          </li>
          <li>
            Le directeur de l’école de danse « Studio Vibes » a eu l’idée de
            créer avec son graphiste, un logo à partager. (cfr. Au logo de cet
            article). C’est un appel à l’aide qui concerne tout le monde. L’idée
            est de partager ce logo partout sur les réseaux sociaux pour inonder
            les fils d’actualité de tout le monde. L’union fait la force et
            cette aide est gratuite
          </li>
          <li>
            Dernièrement, nous avons également évoqué l’idée de rédiger une
            autre pétition que tout le monde pourra signer cette fois-ci. Une
            fois celle-ci rédigée, je vous informerai pour pouvoir signer et
            nous soutenir dans nos démarches.
          </li>
        </ul>
        <p>
          Nous espérons que nos démarches porteront nos fruits et feront réagir
          le gouvernement.
        </p>
        <p>Prenez-soin de vous et protégez-vous un maximum</p>
        <p>Merci à tous !</p>
      </div>
    </article>
  );
 */
