import React, { Fragment } from "react";

const Facebook = () => {
  return (
    <Fragment>
      <div
        className="fb-page"
        data-href="https://www.facebook.com/LesPetitsAbousASBL"
        data-tabs="timeline"
        data-width="445"
        data-height="181"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/LesPetitsAbousASBL"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/LesPetitsAbousASBL">
            Les Petits Abous ASBL
          </a>
        </blockquote>
      </div>
    </Fragment>
  );
};

export default Facebook;
/*
 <div
      className="fb-page is-hidden-mobile"
      data-href="https://www.facebook.com/LesPetitsAbousASBL"
      data-tabs="timeline"
      data-width="445"
      data-height="181"
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote
        cite="https://www.facebook.com/LesPetitsAbousASBL"
        className="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/LesPetitsAbousASBL">
          Les Petits Abous ASBL
        </a>
      </blockquote>
    </div> */
