import React from "react";

import logo from "../../assets/banner03.png";

const Logo = () => {
  return (
    <div>
      <figure className="container">
        <img src={logo} alt="LesPetitsAbous" />
      </figure>
    </div>
  );
};

export default Logo;
