import React from 'react';

// import calendar15 from '../../assets/calendar15.png';
// import calendar20 from '../../assets/calendar20.png';
// import calendar1520 from '../../assets/calendar1520.png';
// import generalcalendar from '../../assets/generalCalendar.jpg';
import calendar23 from '../../assets/calendar24.jpg';

const Home = () => {
  const showQuote = (e) => {
    const el = document.getElementById('quoteMessage');
    if (el.style.display === 'none') {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  };
  const showQuoteGenral = (e) => {
    const el = document.getElementById('quoteMessageGeneral');
    if (el.style.display === 'none') {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  };
  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title has-text-info">A propos de nous</h1>
          <p>
            L’école de danse LES PETITS ABOUS est située à Uccle à Bruxelles. Nous vous proposons un éveil psychomoteur
            adapté aux enfants de 2 à 6 ans et ce, les samedis matins pendant une heure. Les ateliers lient à la fois
            l’amusement, la douceur et l’aspect familial mais aussi les notions spécifiques de la danse chez les
            tout-petits telles que le rythme, le temps, la coordination des mouvements du corps, …
          </p>
          <p>
            Nous vous invitons à naviguer sur notre site internet afin de découvrir toutes les informations utiles. Si
            vous avez une question, n’hésitez pas à prendre contact avec nous via le site ou via notre page Facebook !
            Bonne visite et à très bientôt !
          </p>
        </div>
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title has-text-info">Calendrier</h1>
          <p>
            Ce calendrier peut toujours être modifié selon certains évènements imprévus. Merci de s’informer via la page
            et le profil Facebook ainsi que sur le site internet.
          </p>
          <figure className="image is-16by9">
            <img src={calendar23} alt="Calendrier" />
          </figure>
        </div>
      </div>

      {/* <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <div className="content">
          <h1 className="title has-text-info">Calendrier</h1>
          <p>
            Ce calendrier peut toujours être modifié selon certains évènements imprévus. Merci de s’informer via la page
            et le profil Facebook ainsi que sur le site internet.
          </p>
          <p>
          Calendrier Session Février – Décembre 2021
            <span>
              <a href="#!" onClick={showQuote}>
                *
              </a>
            </span>
          </p>
          <blockquote className="content" id="quoteMessage" style={{ display: 'none' }}>
            * Dû aux nouvelles restrictions gouvernementales, 2 calendriers sont disponibles en fonction de la formule
            prise (15 ou 20 cours).
          </blockquote>
          <figure className="image is-16by9">
            <img src={calendar1520} alt="Calendrier 15 cours" />
          </figure>

          <p>
          Calendrier Session Janvier – Juin 2022 
            <span>
              <a href="#!" onClick={showQuoteGenral}>
                *
              </a>
            </span>
          </p>
          <blockquote className="content" id="quoteMessageGeneral" style={{ display: 'none' }}>
            * Ce calendrier est toujours susceptible de changer pendant l'année.
          </blockquote>
          <figure className="image is-16by9">
            <img src={generalcalendar} alt="Calendrier General" />
          </figure>

          <p className="has-text-right">Modifié le 21/11/2021</p>
        </div>
      </div> */}
    </section>
  );
};

export default Home;
