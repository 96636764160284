import React, { Fragment } from 'react';
import Sparkle from 'react-sparkle';

const Glitters = () => {
  return (
    <Fragment>
      <Sparkle
        style={{ position: 'relative' }}
        color={'#d4af37'}
        // The number of sparkles to render. A large number could slow
        // down the page.
        count={100}
        // The minimum and maximum diameter of sparkles, in pixels.
        minSize={10}
        maxSize={20}
        // The number of pixels the sparkles should extend beyond the
        // bounds of the parent element.
        overflowPx={20}
        // How quickly sparkles disappear; in other words, how quickly
        // new sparkles are created. Should be between 0 and 1000,
        // with 0 never fading sparkles out and 1000 immediately
        // removing sparkles. Most meaningful speeds are between
        // 0 and 150.
        fadeOutSpeed={10}
        // Whether we should create an entirely new sparkle when one
        // fades out. If false, we'll just reset the opacity, keeping
        // all other attributes of the sparkle the same.
        newSparkleOnFadeOut={true}
        // Whether sparkles should have a "flickering" effect.
        flicker={true}
        // How quickly the "flickering" should happen.
        // One of: 'slowest', 'slower', 'slow', 'normal', 'fast', 'faster', 'fastest'
        flickerSpeed={'normal'}
      ></Sparkle>
    </Fragment>
  );
};

export default Glitters;
