import React from "react";
import deconfinement from "../../assets/deconfinement.jpg";

const Deconfinement = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={deconfinement} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">NEWS Déconfinement Coronavirus</h1>
        <div className="content is-size-5">
          <p>
          Après sept semaines de confinement, la Première Ministre a annoncé un déconfinement pour la Belgique par étapes ce 24 avril 2020. « Tout » va pouvoir rouvrir ses portes petit à petit. Mais où se situent les écoles de danse ? La réponse à cette question est encore très floue.
          </p>
          <p>Après plusieurs recherches avec les autres écoles, nous nous sommes rendus compte qu’en effet, les écoles de danse ne sont reprises précisément dans aucune étape. Le mot « loisirs » est très large et ne nous concerne pas.</p>
          <p>
          En effet, les règles d’hygiène et de distanciation sociale sont les raisons principales de notre réouverture tardive. Nous provoquons des regroupements de personnes que le conseil continue de déconseiller.   
          </p>
          <p>
          Aujourd’hui (ce 3 mai 2020), nous avons pu prendre connaissance du circulaire évoquant le futur des mouvements sportifs. « Le 4 mai, selon certaines conditions, l’activité physique et sportive pourra reprendre dans les infrastructures extérieures, moyennant le respect des conditions supplémentaires suivante : 
          </p>
          <ul>
              <li>à l’air libre et sans contact</li>
              <li>avec 2 personnes maximum qui doivent toujours être les mêmes (en plus de celles qui vivent sous le même toit)</li>
              <li>avec le respect des distances de sécurité : Il est impératif de rappeler aux sportifs confirmés ou non de respecter durant leur pratique sportive une distance sanitaire d’au moins 5 mètres afin de tenir compte des phénomènes de transpiration et de ventilation</li>
              <li>les vestiaires et cafétarias sont interdits d’accès</li>
              <li>ces activités sont autorisées uniquement le temps nécessaire à leur exercice. Après l’exercice de ces activités, le retour à son domicile est obligatoire</li>
          </ul>
          <p>
          Comme vous l’aurez constaté, la reprise est possible pour les sports comme le golf, le tennis, la pêche, le kayak … mais avec des règles plus que strictes.
          </p>
          <p>
          A présent, il est toujours stipulé que les entraînements collectifs, extérieurs ou intérieurs, sont interdits et doivent rester fermées jusqu’à nouvel ordre. Nous serons mis au courant de la suite des décisions. Malgré tout, nous savons que la réouverture ne se fera pas en douceur pour autant. Nous devrons également faire face à des conditions strictes, telles que la limitation de personnes, le respect de la distanciation sociale (qui sera, nous le craignons, d’au moins 5 m aussi), limiter un maximum les contacts, les entrées et sorties seront sûrement surveillés, … Et ces règles resteront que nous ouvrons fin mai, en juin ou en septembre. 
          </p>
          <p>
          En attendant, l’équipe des Petits Abous met tout en œuvre pour pouvoir vous accueillir dans les meilleures conditions quand nous aurons l’autorisation de rouvrir nos portes.<br />
          Nous nous réorganisons également pour réussir à vous permettre de récupérer vos pertes causées par ce confinement. Je peux déjà vous confirmer que pour plus de facilités, tout est organisé pour la session prochaine. En fonction de notre date de réouverture, nous étudions plusieurs possibilités comme (par exemple) une réduction, une augmentation des cours, une prolongation de la session, …  Tout cela dépendra bien évidemment de la date de réouverture ainsi que les conditions auxquelles nous devrons faire face.
          </p>
          <p>
          En attente de nouvelles décisions gouvernementales,<br />
A votre disposition pour toutes questions,<br />
L’équipe des Petits Abous
          </p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>03 mail 2020</p>
      </div>
    </div>
  );
};

export default Deconfinement;