import React from "react";
import salle05 from "../../assets/salle01.jpg";
import salle01 from "../../assets/salle02.jpg";
import salle04 from "../../assets/salle03.jpg";
//import salle06 from "../../assets/salle04.jpeg";
// import salle02 from "../../assets/salle05.jpeg";
// import salle03 from "../../assets/salle06.jpeg";
// import salle08 from "../../assets/salle07.jpeg";
// import salle07 from "../../assets/salle08.jpeg";

const Access = () => {
  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + "em" }}>
        <div className="content">
          <p>
            Notre école loue les locaux de « The Motion Factory » qui nous propose une salle d’environ 85m2
            bénéficiant d'un système audio et d’un sas d’entrée.
          </p>
          <p>
            Le centre se compose également d’un vestiaire, de toilettes et d’un espace d'attente.
          </p>
        </div>

        <div className="columns">
          <div className="column is-one-three">
            <figure className="image is-4by3">
              <img src={salle01} alt="" />
            </figure>
          </div>
          {/* <div className="column is-one-quarter">
            <figure className="image is-4by3">
              <img src={salle02} alt="" />
            </figure>
          </div> */}
          {/* <div className="column is-one-quarter">
            <figure className="image is-4by3">
              <img src={salle03} alt="" />
            </figure>
          </div> */}
          <div className="column is-one-three">
            <figure className="image is-4by3">
              <img src={salle04} alt="" />
            </figure>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-three">
            <figure className="image is-4by3">
              <img src={salle05} alt="" />
            </figure>
          </div>
          {/*<div className="column is-one-three">*/}
          {/*  <figure className="image is-4by3">*/}
          {/*    <img src={salle06} alt="" />*/}
          {/*  </figure>*/}
          {/*</div>*/}
          {/* <div className="column is-one-quarter">
            <figure className="image is-4by3">
              <img src={salle07} alt="" />
            </figure>
          </div> */}
          {/* <div className="column is-one-quarter">
            <figure className="image is-4by3">
              <img src={salle08} alt="" />
            </figure>
          </div> */}
        </div>
        <p>Nos locaux se situent à :</p>
        <p>Avenue des Sept Bonniers 68</p>
        <p>B-1180 Bruxelles (Uccle)</p>
        <p>Belgique</p>
        <article className="media">
          <div className="media-content">
            <div className="content">
              <iframe
                title="google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.9749308079545!2d4.333514876309669!3d50.81310316118636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c44e957ebb4d%3A0x2aa18ba7be78ce91!2sAv.%20des%20Sept%20Bonniers%2068%2C%201180%20Uccle!5e0!3m2!1sen!2sbe!4v1693479984711!5m2!1sen!2sbe"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </article>
        <p>Infos pratiques :</p>
        <p>
          <span className="has-text-weight-bold">Tram 51 ou 18 </span> arrêt arrêt Coghen + 5 min à pied
        </p>
        <p>
          <span className="has-text-weight-bold">Bus 48</span> arrêt altitude cent + 5 min à pied
        </p>
        <p>
          <span className="has-text-weight-bold">Bus 54</span> arrêt mystère + 4 min à pied
        </p>
      </div>
    </section>
  );
};

export default Access;
