import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import "./App.scss";

import Header from "./components/layout/Header";
import Home from "./components/pages/Home";
import Cours from "./components/pages/Cours";
import News from "./components/pages/News";
import Shopping from "./components/pages/Shopping";
import Access from "./components/pages/Access";
import NotFound from "./components/pages/NotFound";
import Contact from "./components/pages/Contact";
import Register from "./components/pages/Register";

import Footer from "./components/layout/Footer";

const App = () => {
  ReactGA.initialize("UA-154809843-3");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <section className="App section">
      <Router>
        <Header />
        <div className="container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/cours" element={<Cours />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/shop" element={<Shopping />} />
            <Route exact path="/access" element={<Access />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/register" element={<Register />} />
            <Route path="*" element={NotFound} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </section>
  );
};

export default App;
