import React from "react";

import notredeconfinement from "../../assets/notredeconfinement.jpg";

const NotreDeconfinment = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={notredeconfinement} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">Notre déconfinement!!</h1>
        <div className="content is-size-5">
          <p>Bonjour à tous</p>
          <p>
            Comme vous l'avez sans doute déjà entendu, nous pourrons normalement
            reprendre nos activités dès le 8 juin. Beaucoup d'entre vous ont
            indiqué votre retour ainsi nos cours du 13 et 20 juin pourront donc
            être ouverts. Les conditions sont encore un peu à préciser par le
            biais du protocole pour les activités sportives que nous
            attendons...
          </p>
          <p>
            <a
              href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.rtbf.be%2Finfo%2Fbelgique%2Fdetail_competitions-salles-de-fitness-stages-faire-du-sport-redevient-possible-a-annonce-le-conseil-national-de-securite%3Fid%3D10514690%26utm_source%3Drtbfinfo%26utm_campaign%3Dsocial_share%26utm_medium%3Dfb_share%26fbclid%3DIwAR3kcYx0DgrLjywVIvz4IJQG0OqmGx5ewQlDYFPMnH3zlT5yILUS1pu5hLU&h=AT367wGu6Tjj61Fyx2jAmdJwjBQ-ChMDv48Bymppow1gxp7iA14tL-kC3URw1hf_EUe60l30RZSBmLL2xD-eRfRNkC6W-SQSxT_dTog7QbU7wx9uyOpqZy1cobrhOAKKMZNV&__tn__=-UK-R&c[0]=AT1kh3AWXGF5hCmQ5fx9ykNiDdxd30uZorqARPM1mkEqhpQrIP0AmKIa1w0OdUqHsoZf-acgel6pw6wQjad-_eYMnJRIyjSn4TtRCDu4SQ8m2RzTRhLDNVw2xr5jEhVGtehkAE2qk0qDvFWcICYG"
              target="_blank"
              rel="noopener noreferrer"
            >
              Liens vers l'information
            </a>
          </p>
          <p>Nous revenons rapidement vers vous avec plus d'informations !</p>
          <p>L'équipe des Petits Abous </p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>03 juin 2020</p>
      </div>
    </div>
  );
};

export default NotreDeconfinment;
