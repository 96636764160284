import React, { Fragment } from "react";

const Step3 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">
            2 – Quelques information sur les parents{" "}
          </h1>
        </header>
        <div className="card-content">
          {/* BEGIN CASE MOM */}
          {values.ouVitEnfant === "chez maman" && (
            /* BEGIN NAME MOM*/
            <div className="content">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">NOM de la maman</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.nomMaman}
                        onChange={onChange}
                        name={"nomMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END NAME MOM*/} {/* BEGIN Firstname MOM*/}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">PRENOM de la maman</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.prenomMaman}
                        onChange={onChange}
                        name={"prenomMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END Firstname MOM*/} {/* BEGIN PHONE MOM */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">Téléphone accessible</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="tel"
                        defaultValue={values.telephoneMaman}
                        onChange={onChange}
                        name={"telephoneMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END PHONE MOM */} {/* BEGIN MAIL MOM */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">E-mail</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="email"
                        defaultValue={values.emailMaman}
                        onChange={onChange}
                        name={"emailMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END MAIL MOM */}
            </div>
          )}
          {/* END CASE MOM */}
          {/* BEGIN CASE DAD */}
          {values.ouVitEnfant === "chez papa" && (
            <div className="content">
              {/* BEGIN DAD NAME */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">NOM du papa</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.nomPapa}
                        onChange={onChange}
                        name={"nomPapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END NAME DAD*/} {/* BEGIN Firstname Dad*/}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">PRENOM du papa</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.prenomPapa}
                        onChange={onChange}
                        name={"prenomPapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END Firstname DAD*/}
              {/* BEGIN PHONE DAD */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">Téléphone accessible</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="tel"
                        defaultValue={values.telephonePapa}
                        onChange={onChange}
                        name={"telephonePapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END PHONE DAD */} {/* BEGIN MAIL DAD */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">E-mail</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="email"
                        defaultValue={values.emailPapa}
                        onChange={onChange}
                        name={"emailPapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END MAIL DAD */}
            </div>
          )}
          {/* END CASE DAD */}
          {/* BEGIN CASE BOTH */}
          {values.ouVitEnfant === "chez les deux" && (
            <div className="content">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">NOM de la maman</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        onChange={onChange}
                        defaultValue={values.nomMaman}
                        name={"nomMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END NAME MOM*/} {/* BEGIN Firstname MOM*/}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">PRENOM de la maman</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.prenomMaman}
                        onChange={onChange}
                        name={"prenomMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END Firstname MOM*/} {/* BEGIN PHONE MOM */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">Téléphone accessible</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="tel"
                        defaultValue={values.telephoneMaman}
                        onChange={onChange}
                        name={"telephoneMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END PHONE MOM */} {/* BEGIN MAIL MOM */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">E-mail</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="email"
                        defaultValue={values.emailMaman}
                        onChange={onChange}
                        name={"emailMaman"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END MAIL MOM */}
              {/* BEGIN NAME DAD */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">NOM du papa</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.nomPapa}
                        onChange={onChange}
                        name={"nomPapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END NAME DAD*/} {/* BEGIN Firstname Dad*/}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">PRENOM du papa</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        defaultValue={values.prenomPapa}
                        onChange={onChange}
                        name={"prenomPapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END Firstname DAD*/} {/* BEGIN PHONE DAD */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">Téléphone accessible</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="tel"
                        defaultValue={values.telephonePapa}
                        onChange={onChange}
                        name={"telephonePapa"}
                      />
                    </p>
                  </div>

                </div>
              </div>
              {/* END PHONE DAD */} {/* BEGIN MAIL DAD */}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="" className="label">
                    <span className="is-size-5">E-mail</span>
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        type="email"
                        defaultValue={values.emailPapa}
                        onChange={onChange}
                        name={"emailPapa"}
                      />
                    </p>
                  </div>
                  
                </div>
              </div>
              {/* END MAIL DAD */}
            </div>
          )}
          {/* END CASE BOTH */}
          <div className="notification is-info">
            <p>
              Ceci est seulement pour vous assurer une bonne communication au
              cours de l’année. C’est par là que je vous informerai sur toutes
              les informations utiles et les dates importantes. De plus, en cas
              d’absence, vous serez également prévenu par là. En addition, c’est
              avec ces données que je vous préviendrai en cas d’urgence.
            </p>
          </div>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step3;
