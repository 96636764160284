import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const ShoppingForm = ({ showMessage, setShowMessage }) => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.subject = "Passer une commande";
    data.from = process.env.REACT_APP_FORM_NOREPLY;
    data.destination = process.env.REACT_APP_FORM_SENTTO_EMAIL;
    try {
      await axios.post(
        "https://api.denegee.be/api/email",
        data
      );
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
    if (!showMessage) {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }

    onReset();
  };

  const onReset = () => {
    reset();
    toggleModal();
  };

  const toggleModal = () => {
    const modal = document.getElementById("modalOrder");
    modal.classList.toggle("is-active");
  };

  return (
    <div className="modal" id="modalOrder">
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Passer une commande</p>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggleModal}
          ></button>
          {loading && (
            <progress className="progress is-large is-warning" max="100">
              15%
            </progress>
          )}
        </header>
        <section className="modal-card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <div className="label">Votre nom</div>
              <div className="control has-icons-left">
                <input
                  id="inputName"
                  className="input"
                  type="text"
                  placeholder="Votre nom"
                  {...register("name", {required: true})}
                  onChange={onChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                {/*{errors.name && (*/}
                {/*  <p className="has-text-danger">*/}
                {/*    <span>*/}
                {/*      <i className="fas fa-exclamation-triangle"></i>*/}
                {/*    </span>*/}
                {/*    Votre nom est obligatoire*/}
                {/*  </p>*/}
                {/*)}*/}
              </div>
            </div>
            <div className="field">
              <div className="label">Votre adresse mail</div>
              <div className="control has-icons-left has-icons-right">
                <input
                  id="inputEmail"
                  className="input"
                  type="email"
                  placeholder="Votre adresse mail"
                  {...register("email", {required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                  onChange={onChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Que désirez vous?</label>
              <div className="control">
                <textarea
                  className="textarea"
                  {...register("message", {required: true})}
                  onChange={onChange}
                ></textarea>
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            Envoyer
          </button>
          <button className="button" onClick={onReset}>
            Annuler
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ShoppingForm;
