import React from "react";

const Instagram = () => {
  return (
    <div>
      <p>
        <a
          href="https://www.instagram.com/lespetitsabousasbl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram text-primary fa-2x"></i> @lespetitsabousasbl
        </a>
      </p>
    </div>
  );
};

export default Instagram;
