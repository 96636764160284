import React from "react";
import cancelled from "../../assets/cancelled.jpg";

const ImpactCorona = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={cancelled} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">
          Impact du Coronavirus et de son confinement sur nos activités
        </h1>
        <div className="content is-size-5">
          <p>
            Pour donner suite aux nouvelles mesures décidées par le
            gouvernement, Les Petits Abous ferment jusqu'à nouvel ordre.
          </p>
          <p>
            "Toutes les activités récréatives, sportives, culturelles ou
            folkloriques, qu'elles soient publiques ou privées et "peu importe
            leur taille", sont supprimées.". Je me dois de penser à la sécurité
            de vos enfants et ne prendre aucun risque de contagion, j'en suis
            désolée...
          </p>
          <p>
            Protégez-vous, restez un maximum chez vous si vous le pouvez et je
            vous tiendrai au courant pour la suite.
          </p>
          <p>L'équipe des Petits Abous.</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>14 mars 2020</p>
      </div>
    </div>
  );
};

export default ImpactCorona;

/**
 * <article className="media">
      <figure className="media-left">
        <p className="image is-128x128">
          <img src={cancelled} alt="" />
        </p>
      </figure>
      <div className="media-content">
        <span style={{ float: "right" }}>14 mars 2020</span>
        <h1 className="title has-text-info ">
          Impact du Coronavirus et de son confinement sur nos activités
        </h1>
        <p>
          Pour donner suite aux nouvelles mesures décidées par le gouvernement,
          Les Petits Abous ferment jusqu'à nouvel ordre.
        </p>
        <p>
          "Toutes les activités récréatives, sportives, culturelles ou
          folkloriques, qu'elles soient publiques ou privées et "peu importe
          leur taille", sont supprimées.". Je me dois de penser à la sécurité de
          vos enfants et ne prendre aucun risque de contagion, j'en suis
          désolée...
        </p>
        <p>
          Protégez-vous, restez un maximum chez vous si vous le pouvez et je
          vous tiendrai au courant pour la suite.
        </p>
        <p>L'équipe des Petits Abous.</p>
      </div>
    </article>
 */
