import React from "react";
import petitions from "../../assets/petitions.jpg";

const Petitions = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={petitions} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">#JESIGNEPOURLADANSE</h1>
        <div className="content is-size-5">
          <p>
            Il faut continuer à partager un maximum pour que tout le monde signe
            cette pétition …
          </p>
          <p>Merci beaucoup !</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>24 avril 2020</p>
      </div>
    </div>
  );
};

export default Petitions;
/**
 * <article className="media">
      <div className="media-left">
        <figure className="image is-3by2">
          <img src={petitions} alt="" />
        </figure>
      </div>
      <div className="media-content">
        <span style={{ float: "right" }}>24 avril 2020</span>
        <h1 className="title has-text-info ">#JESIGNEPOURLADANSE</h1>
        <p>
          Il faut continuer à partager un maximum pour que tout le monde signe
          cette pétition …
        </p>
        <p>Merci beaucoup !</p>
      </div>
    </article>
 */
