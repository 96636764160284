import React, { useState } from "react";

import RegisterCode from "../registerComponents/RegisterCode";
import StartForm from "../registerComponents/StartForm";
//import axios from "axios";

const Register = () => {
  const [accepted, setAccepted] = useState(false);

  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + "em" }}>
        <h1 className="title has-text-info has-text-centered">
          Formulaire d'inscription
        </h1>
        {!accepted && (
          <RegisterCode accepted={accepted} setAccepted={setAccepted} />
        )}
        {accepted && (
          <StartForm accepted={!accepted} setAccepted={setAccepted} />
        )}
      </div>
    </section>
  );
};

export default Register;
