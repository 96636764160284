import React, { useState } from "react";
import { useForm } from "react-hook-form";

const RegisterCode = ({ accepted, setAccepted }) => {
  const { register, handleSubmit } = useForm();
  const [wrongCode, setWrongCode] = useState(false);

  const onSubmit = (data) => {
    if (process.env.REACT_APP_FORM_CODE === data.code) {
      setWrongCode(false);
      setAccepted(true);
    } else {
      setWrongCode(true);
      setAccepted(false);
      //errors.code = true;
    }
  };

  return (
    <div className="columns is-mobile is-centered">
      <div className="column is-half">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field has-addons">
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Code"
                {...register("code", { required: true })}
              />
            </div>
          </div>
          <p>
            Ce code vous sera donné après discussion avec la Directrice de
            l'ASBL
          </p>
        </form>
        <div className="control has-text-right">
          <button className="button is-info " onClick={handleSubmit(onSubmit)}>
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterCode;

/** style={{
    ...defaultStyle,
    ...transitionStyles[state],
  }} */
