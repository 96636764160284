import React from "react";

import Logo from "./Logo";
import Navigation from "./Navigation";
import Glitters from "../Glitters";

const Header = () => {
  return (
    <section>
      <div className="container has-text-centered">
        <Glitters />
        <Logo />
      </div>
      <div className="container">
        <Navigation />
      </div>
    </section>
  );
};

export default Header;
