import React from 'react';
import Facebook from '../Facebook';

import logo from '../../assets/banner03.jpg';
import Glitters from '../Glitters';
const Footer = () => {
  return (
    <footer className="container">
      <Glitters />
      <div className="box has-background-success">
        <div className="columns is-3-desktop">
          <div className="column"></div>
          <div className="column">
            <figure className="image is-hidden-mobile" style={{ width: '445px' }}>
              <img src={logo} alt="LesPetitsAbous" />
            </figure>
          </div>
          <div className="column">
            <Facebook />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
