import React from "react";
import preserveDance from "../../assets/preserveDance.png";

const PreserveDance = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-5by3">
          <img src={preserveDance} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">
          Pétition de soutien pour les écoles de danse
        </h1>
        <div className="content is-size-5">
          <p>
            Comme prévenu dans l’article précédent, je vous partage la pétition
            réalisée par Dance Corner dans le but de soutenir les écoles de
            danse dans cette période difficile.
          </p>
          <p>
            <a
              href="https://www.dance-corner.org/petition"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien de la pétition
            </a>
          </p>
          <p>
            Cette pétition est ouverte à tous, parents, élèves, professeurs,
            proches, …
          </p>
          <p>
            On compte sur vous pour récolter un maximum de signatures pour
            espérer faire avancer les choses
          </p>
          <p>A PARTAGER UN MAXIMUM</p>
          <p>Merci d’avance pour votre soutien qui nous est si précieux</p>
          <p>Les Petits Abous</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>22 avril 2020</p>
      </div>
    </div>
  );
};

export default PreserveDance;
/**
 * <article className="media is-desktop">
      <figure className="media-left">
        <p className="image is-128x128">
          <img src={preserveDance} alt="" />
        </p>
      </figure>
      <div className="media-content">
        <span style={{ float: "right" }}>22 avril 2020</span>
        <h1 className="title has-text-info ">
          Pétition de soutien pour les écoles de danse
        </h1>
        <p>
          Comme prévenu dans l’article précédent, je vous partage la pétition
          réalisée par Dance Corner dans le but de soutenir les écoles de danse
          dans cette période difficile.
        </p>
        <p>
          <a
            href="https://www.dance-corner.org/petition"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lien de la pétition
          </a>
        </p>
        <p>
          Cette pétition est ouverte à tous, parents, élèves, professeurs,
          proches, …
        </p>
        <p>
          On compte sur vous pour récolter un maximum de signatures pour espérer
          faire avancer les choses
        </p>
        <p>A PARTAGER UN MAXIMUM</p>
        <p>Merci d’avance pour votre soutien qui nous est si précieux</p>
        <p>Les Petits Abous</p>
      </div>
    </article>
 */
