import React, { Fragment } from "react";

const Step6 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  //console.log(values);
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">5 - Autorisation de filmer</h1>
        </header>
        <div className="card-body">
          <p className="notification is-info">
            Dans le cadre de notre mission d'encadrement d'animation de danse, nous sommes amenés à filmer et photographier les participants pendant les activités.
            Ces photos et vidéos seront publiées sur le profile privé des Petits Abous (Talisa Abous) et sont susceptible d'être utilisées pour la promotion de nos activités.
          </p>
          <label className="checkbox">
            <input
              type="checkbox"
              value={"oui"}

              onChange={onChange}
              name={"authorisationImage"}
            />
            <span className="is-size-5">
              J’autorise Talisa à filmer et photographier mon enfant.
            </span>
          </label>
          <label className="checkbox">
            <input
              type="checkbox"
              value={"non"}
              // ved={values.authorisationImage === "non"}
              onChange={onChange}
              name={"authorisationImage"}
            />
            <span className="is-size-5">
              Je refuse que Talisa filme et photographie mon enfant.
            </span>
          </label>

          <div className="field">
            <div className="control ">
              <label htmlFor="profileFacebook" className="label is-size-5">
                Profil(s) Facebook que je dois ajouter avec ce compte avec le
                Profil Privé : Talisa Abous
              </label>
              <textarea
                className="textarea"
                onChange={onChange}
                defaultValue={values.profileFacebook}
                name={"profileFacebook"}
              ></textarea>
            </div>
          </div>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step6;
