import React, { Fragment } from "react";
import ROI from "../../assets/ROI.pdf";

const Step7 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  //console.log(values);
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">6 - Acte de fidélité</h1>
        </header>
        <div className="card-body">
          <label className="checkbox">
            <input
              type="checkbox"
              value={"oui"}

              onChange={onChange}
              name={"luEtApprove"}
            />
            <span className="is-size-5">
              Je déclare avoir lu et est d’accord avec le règlement{" "}
              <a href={ROI} target="_blank" rel="noopener noreferrer">
                d’ordre intérieur
              </a>
              .
            </span>
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              value={"oui"}

              onChange={onChange}
              name={"payerTotalite"}
            />
            <span className="is-size-5">
              Je m’engage à payer la totalité de la somme indiquée sur la
              facture sur le compte bancaire indiqué et dans le délai imposé. Le
              délai est au plus tard 1 mois avant le premier cours, sauf cas
              exceptionnel d’inscription tardive.
            </span>
          </label>

        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step7;
