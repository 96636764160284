import React, { Fragment } from "react";

const Step2 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">1 – Identification de l’élève </h1>
        </header>
        <div className="card-content">
          <div className="content">
            {/* BEGIN CHILDNAME */}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">NOM de l’enfant</span>
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      defaultValue={values.nomEnfant}
                      onChange={onChange}
                      name={"nomEnfant"}

                    />
                  </p>
                </div>

              </div>
            </div>
            {/* END CHILDNAME */}
            {/* BEGIN CHILDFIRSTNAME */}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">PRENOM de l’enfant</span>
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      defaultValue={values.prenomEnfant}
                      onChange={onChange}

                        name={"prenomEnfant"}
                    />
                  </p>
                </div>

              </div>
            </div>{" "}
            {/* END CHILDFIRSTNAME */}
            {/* BEGIN CHILDGENDER */}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">Sexe</span>
                </label>
              </div>
              <div className="field-body">
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      value={"fille"}
                      onChange={onChange}
                      checked={values.sexeEnfant === "fille"}

                        name={"sexeEnfant"}
                    />
                    <span className="is-size-5">Fille</span>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      value={"garçon"}
                      checked={values.sexeEnfant === "garçon"}
                      onChange={onChange}

                        name={"sexeEnfant"}
                    />
                    <span className="is-size-5">Garçon</span>
                  </label>
                </div>
              </div>

            </div>
            {/* END CHILDGENDER */}
            {/* BEGIN CHILDGAGE */}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">Age</span>
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      defaultValue={values.ageEnfant}
                      onChange={onChange}

                        name={"ageEnfant"}
                    />
                  </p>
                </div>

              </div>
            </div>
            {/* END CHILDAGE */}
            {/* BEGIN CHILDBIRTH */}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">Date de naissance</span>
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="date"
                      defaultValue={values.naissanceEnfant}
                      onChange={onChange}

                        name={"naissanceEnfant"}
                    />
                  </p>
                </div>

              </div>
            </div>
            {/* END CHILDBIRTH */}
            {/* BEGIN WHERE LIVE THE CHILD*/}
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="" className="label">
                  <span className="is-size-5">L'enfant vit</span>
                </label>
              </div>
              <div className="field-body">
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      value={"chez maman"}
                      onChange={onChange}
                      checked={values.ouVitEnfant === "chez maman"}

                        name={"ouVitEnfant"}
                    />
                    <span className="is-size-5">Chez maman</span>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      value={"chez papa"}
                      checked={values.ouVitEnfant === "chez papa"}
                      onChange={onChange}

                        name={"ouVitEnfant"}
                    />
                    <span className="is-size-5">Chez papa</span>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      value={"chez les deux"}
                      onChange={onChange}
                      checked={values.ouVitEnfant === "chez les deux"}

                        name={"ouVitEnfant"}
                    />
                    <span className="is-size-5">Chez les deux</span>
                  </label>
                </div>
              </div>

            </div>
            {/* END WHERE LIVE THE CHILD*/}
            {/* BEGIN ADDRESSE FIELD*/}
            <div className="field">
              <label className="label is-size-5">Adresse(s) complète(s)</label>
              <div className="control">
                <textarea
                  className="textarea"
                  onChange={onChange}
                  defaultValue={values.adresseEnfant}

                    name={"adresseEnfant"}
                ></textarea>
              </div>
            </div>

            {/* END ADDRESSE FIELD*/}
          </div>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step2;
