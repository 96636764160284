import React, { Fragment } from "react";

const Step5 = ({
  register,
  errors,
  handleSubmit,
  onChange,
  nextStep,
  previousStep,
  values,
}) => {
  //console.log(values);
  const continueStep = (e) => {
    //e.preventDefault();
    nextStep();
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <h1 className="card-header-title">4 - En cas d'urgence</h1>
        </header>
        <div className="card-body">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label htmlFor="" className="label">
                <span className="is-size-5">Téléphone</span>
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="tel"
                    defaultValue={values.iceTelephone}
                    onChange={onChange}
                    name={"iceTelephone"}
                  />
                </p>
              </div>

            </div>
          </div>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button
            className="button is-info card-footer-item"
            onClick={handleSubmit(continueStep)}
          >
            Suivant
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Step5;
