import React from 'react';

import Gouvernement from '../newsItems/Gouvernement';
import ImpactCorona from '../newsItems/ImpactCorona';
import PreserveDance from '../newsItems/PreserveDance';
import Petitions from '../newsItems/Petitions';
import Deconfinement from '../newsItems/Deconfinement';
import FausseAlert from '../newsItems/FausseAlert';
import NotreDeconfinment from '../newsItems/NotreDeconfinment';
import IndoorSport from '../newsItems/IndoorSport';
import NewSession from '../newsItems/NewSession';
import Hourra from 'components/newsItems/Hourra';
import Session2022 from 'components/newsItems/Session2022';
const News = () => {
  return (
    <section>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <Hourra />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <Session2022 />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <NewSession />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <IndoorSport />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <NotreDeconfinment />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <FausseAlert />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <Deconfinement />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <Petitions />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <PreserveDance />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <Gouvernement />
      </div>
      <div className="box has-background-success" style={{ margin: 1 + 'em' }}>
        <ImpactCorona />
      </div>
    </section>
  );
};

export default News;

/**<div className="tile is-ancestor">
          <div className="tile is-parent">
            <PreserveDance />
          </div>

          <div className="tile is-6 is-parent">
            <Petitions />
          </div>

          <div className="tile is-vertical is-parent">
            <article className="tile is-child gold post"></article>
          </div>
        </div>

        <div className="tile is-ancestor">
          <div className="tile is-6 is-parent">
            <Gouvernement />
          </div>

          <div className="tile is-vertical is-parent">
            <ImpactCorona />
          </div>
        </div>

        <div className="tile is-ancestor">
          <div className="tile is-8 is-vertical">
            <div className="tile">
              <div className="tile is-parent">
                <article className="tile is-child blue post"></article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child gray post"></article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child red post"></article>
              </div>
            </div>

            <div className="tile">
              <div className="tile is-parent">
                <article className="tile is-child pink post"></article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child gold post"></article>
              </div>
            </div>
          </div>

          <div className="tile is-parent"></div>
        </div> */
