import React from 'react';
import session from '../../assets/session2022.png';

const Session2022 = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={session} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">Informations Session Janvier à Juin 2022</h1>
        <div className="content is-size-5">
          <p>
            Ce que je peux déjà annoncer pour la session de janvier 2022 ... (si aucune nouvelle restriction sanitaire
            nous oblige à modifier nos plans) Ehh oui, vous avez bien vu, nous revenons à 2 groupes qui auront 15 cours
            toutes les semaines.
          </p>
          <p>L'équipe des Petits Abous</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>20 novembre 2021</p>
      </div>
    </div>
  );
};

export default Session2022;
