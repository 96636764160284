import React from "react";
import RegisterForm from "./RegisterForm";

const StartForm = ({ accepted, setAccepted }) => {
  return (
    <div className="content">
      <RegisterForm />
    </div>
  );
};

export default StartForm;

/** style={{
    ...defaultStyle,
    ...transitionStyles[state],
  }} */
