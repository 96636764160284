import React, { Fragment, useState } from 'react';
import axios from 'axios';

const Confirm = ({ nextStep, previousStep, values }) => {
  const [loading, setLoading] = useState(false);
  const {
    nouvelEnfant,
    choixDeSessions,
    nomEnfant,
    prenomEnfant,
    sexeEnfant,
    ageEnfant,
    naissanceEnfant,
    ouVitEnfant,
    adresseEnfant,
    nomMaman,
    prenomMaman,
    telephoneMaman,
    emailMaman,
    nomPapa,
    prenomPapa,
    telephonePapa,
    emailPapa,
    santeEnfant,
    iceTelephone,
    authorisationImage,
    profileFacebook,
    soeurFrere,
  } = values;

  const continueStep = async (e) => {
    //e.preventDefault();
    setLoading(true);
    values.subject = 'Inscription';
    const message = createMessage(values);
    message.from = process.env.REACT_APP_FORM_NOREPLY;
    try {
      await axios.post('https://api.denegee.be/api/email', message);
    } catch (error) {
      console.error(error.message);
    }

    setLoading(false);
    nextStep();
  };

  const createMessage = (data) => {
    const ret = {};
    ret.destination = process.env.REACT_APP_FORM_SENTTO_EMAIL;
    ret.subject = data.subject;
    ret.email = data.emailMaman !== '' ? data.emailMaman : data.emailPapa;
    ret.message = renderEmail(data);
    return ret;
  };

  const renderEmail = (data) => {
    let ret = '';
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        let string = '<p>' + key + '==> ' + element + '</p>';
        ret += string;
      }
    }
    return ret;
  };

  const backStep = (e) => {
    //e.preventDefault();
    previousStep();
  };
  return (
    <Fragment>
      {loading && (
        <progress className="progress is-large is-warning" max="100">
          15%
        </progress>
      )}
      <div className="card">
        <header className="card-header">
          <h1>Verifiez vos données:</h1>
        </header>
        <div className="card-content">
          <p>
            <span className="has-text-weight-bold">Est-ce c'est un nouvel enfant? </span>
            {nouvelEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Vous avez choisi la session: </span>
            {choixDeSessions}
          </p>
          <p>
            <span className="has-text-weight-bold">Le nom de l'enfant: </span>
            {nomEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Le prénom de l'enfant: </span>
            {prenomEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Le sexe de l'enfant: </span>
            {sexeEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Quel age a l'enfant: </span>
            {ageEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">La date de naissance de l'enfant: </span>
            {naissanceEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Ou vit l'enfant: </span>
            {ouVitEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Adresse de l'enfant: </span>
            {adresseEnfant}
          </p>
          {ouVitEnfant === 'chez maman' && (
            <div>
              <p>
                <span className="has-text-weight-bold">Le nom de maman: </span>
                {nomMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Le prénom de maman: </span>
                {prenomMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Le téléphone de maman: </span>
                {telephoneMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Adresse mail de maman': </span>
                {emailMaman}
              </p>
            </div>
          )}
          {ouVitEnfant === 'chez papa' && (
            <div>
              <p>
                <span className="has-text-weight-bold">Le nom de papa: </span>
                {nomPapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Le prénom de papa: </span>
                {prenomPapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Le téléphone de papa: </span>
                {telephonePapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Adresse mail de papa: </span>
                {emailPapa}
              </p>
            </div>
          )}
          {ouVitEnfant === 'chez les deux' && (
            <div>
              <p>
                <span className="has-text-weight-bold">Le nom de maman: </span>
                {nomMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Le prénom de maman: </span>
                {prenomMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Le téléphone de maman: </span>
                {telephoneMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Adresse mail de maman': </span>
                {emailMaman}
              </p>
              <p>
                <span className="has-text-weight-bold">Le nom de papa: </span>
                {nomPapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Le prénom de papa: </span>
                {prenomPapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Le téléphone de papa: </span>
                {telephonePapa}
              </p>
              <p>
                <span className="has-text-weight-bold">Adresse mail de papa: </span>
                {emailPapa}
              </p>
            </div>
          )}
          <p>
            <span className="has-text-weight-bold">Problèmes de santé à me signaler: </span>
            {santeEnfant}
          </p>
          <p>
            <span className="has-text-weight-bold">Téléphone en cas d'urgence: </span>
            {iceTelephone}
          </p>
          <p>
            <span className="has-text-weight-bold">Ai-je reçu l'autorisation de filmer l'enfant? </span>
            {authorisationImage}
          </p>
          <p>
            <span className="has-text-weight-bold">Quel profil Facebook dois-je ajouter? </span>
            {profileFacebook}
          </p>
          <p>
            <span className="has-text-weight-bold">Y a t il deja un frère ou une soeur?: </span>
            {soeurFrere}
          </p>
        </div>
        <footer className="card-footer">
          <button className="button card-footer-item" onClick={backStep}>
            Précédent
          </button>
          <button className="button is-info card-footer-item" onClick={continueStep} disabled={loading}>
            Envoyer
          </button>
        </footer>
      </div>
    </Fragment>
  );
};

export default Confirm;
