import React from 'react';
import hourra from '../../assets/hourra.png';

const Hourra = () => {
  return (
    <div className="columns">
      <div className="column is-one-quarter">
        <figure className="image is-square">
          <img src={hourra} alt="" />
        </figure>
      </div>
      <div className="column">
        <h1 className="title has-text-info ">CODECO : passage en code jaune dès le 7 mars !</h1>
        <div className="content is-size-5">
          <p>De bonnes nouvelles pour le sport et la jeunesse !</p>
          <ul>
            <li>Les masques et les CST, c'est fini !</li>
            <li>
              Pas de limitation concernant le nombre de participants en intérieur mais une attention accrue à la
              ventilation est demandée.
            </li>
            <li>Rester vigilants à l'hygiène des mains et aux contacts</li>
            <li>Une nouvelle organisation est donc faite (cfr. Groupe privé des Petits Abous)</li>
          </ul>
          <p>Au plaisir de vous retrouver comme avant</p>
          <p>L'équipe des Petits Abous</p>
        </div>
      </div>
      <div className="column is-one-fifth">
        <p>05 mars 2022</p>
      </div>
    </div>
  );
};

export default Hourra;
