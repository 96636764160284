import React from "react";

const NotFound = () => {
  return (
    <section>
      <h1 className="title is-size-1 has-text-danger has-text-centered">
        La page demandée n'est pas attribuée
      </h1>
    </section>
  );
};

export default NotFound;
