import React, { Fragment } from "react";

const Success = () => {
  return (
    <Fragment>
      <div className="has-text-centered">
        <h1 className="title has-text-info">
          Votre demande d’inscription a été envoyée avec succès et est en
          attente d’approbation. La directrice de l’asbl vous recontactera au
          plus vite pour les modalités de paiements.
        </h1>
      </div>
    </Fragment>
  );
};

export default Success;
